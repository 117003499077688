.m-hero {
  position: static;

  &__header {
    margin: 0 0 20px;
    font-size: 2rem;
    color: inherit;
    hyphens: auto;

    @media screen and (min-width: $screen-sm-min) {
      margin-bottom: map-get($spacers, 7);
      font-size: 3.5rem;
    }

    @media screen and (min-width: $screen-md-min) {
      font-size: 5rem;
      hyphens: manual;
    }

    &--slim {
      @media screen and (min-width: $screen-sm-min) {
        font-size: 3.75rem;
      }

      .m-hero__title {
        &:after {
          display: none;
        }
      }
    }

    .m-hero__col--center & {
      text-align: center;
    }
  }

  &__menu-teaser {
    margin-top: -20px;

    @media screen and (min-width: $screen-sm-min) {
      margin-top: (map-get($spacers, 7) / -1);
    }
  }

  &__sup-title {
    @include font-smoothing;
    font-size: 1.5rem;
    font-weight: $font-weight-base;

    @media screen and (min-width: $screen-sm-min) {
      font-size: 2.75rem;
    }

    @media screen and (min-width: $screen-md-min) {
      font-size: 3.75rem;
    }
  }

  &__title {
    margin: 0.4em 0;
    display: block;
    font-weight: $font-weight-bold;
    font-size: inherit;
    color: inherit;

    &:after {
      @include line-ornament-double(null, 2px, .5em 0 0);
    }

    &--simple {
      &:after {
        display: none;
      }
    }
  }

  &__description {
    margin-bottom: 30px;
    font-size: 1rem;

    @media screen and (min-width: $grid-float-breakpoint) {
      margin-bottom: map-get($spacers, 7);
    }

    .m-hero__col--center & {
      text-align: center;
    }
  }

  &__list {
    @include clearfix;
    margin-bottom: map-get($spacers, 8) !important; // override Splide reset
  }

  &__content {
    width: 100%;
    outline: none;
  }

  &__action {
    margin-bottom: 15px;

    .m-hero__col--center & {
      text-align: center;
    }
  }

  &__indicators {
    @include position(absolute, null 0 $grid-gutter-height 0);
    text-align: center;

    @media screen and (min-width: $screen-md-min) {
      @include position(absolute, 50% $grid-gutter-width auto auto);

      &--left {
        right: auto;
        left: $grid-gutter-width;
      }
    }
  }

  @media screen and (min-width: $grid-float-breakpoint) {
    text-align: center;
  }

  &__row {
    display: flex;
    margin-right: floor($grid-gutter-width / -2);
    margin-left: floor($grid-gutter-width / -2);
    align-items: center;
    margin-bottom: $base-spacing;
  }

  &__col {
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    flex: 1 1 50%;

    &--media {
      @media screen and (max-width: $screen-sm-max) {
        display: none;
      }
    }

    &--center {
      margin-right: auto;
      margin-left: auto;
      flex-basis: auto;

      @media (min-width: $screen-sm-min) {
        max-width: 768px;
      }
    }
  }

  &__col-inner {
    &--media-next {
      @media screen and (min-width: $screen-md-min) {
        margin-left: auto;
        max-width: 600px;
      }
    }

    &--media-prev {
      @media screen and (min-width: $screen-md-min) {
        margin-right: auto;
        max-width: 600px;
      }
    }
  }

  &__logo-wrapper {
    text-align: center;
  }

  &__logo {
    display: inline-block;
  }

  &--subpage {
    margin-block-end: 10px;
    text-align: center;

    @media screen and (min-width: $grid-float-breakpoint) and (min-height: $screen-sm-min) {
      margin-block: 20px;
    }

    .l-checkout & {
      @media screen and (max-width: $grid-float-breakpoint-max) {
        display: none;
      }
    }
  }

  &--media {
    text-align: left;

    .m-hero__title {
      &:after {
        margin-left: 0;
      }

      @media screen and (min-width: $screen-md-min) {
        font-size: 3.75rem;
      }
    }
  }
}
