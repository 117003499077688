.m-icon-group {
  &__list {
    @include list-unstyled;
  }

  &__list-item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0;
    }

    .m-icon-group--vertical & {
      display: block;
      margin-right: 0;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__item {
    &,
    &.btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 50px;
      height: 50px;
      text-align: center;
      border-radius: 50%;
      font-size: 16px;
    }

    &--bare-hero {
      width: 40px;
      font-size: 120%;
    }
  }
}
