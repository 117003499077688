// 1. Shorthand notation doesn't work correctly in IE 11.
.m-promo-boxes {
  &__list {
    display: flex;
    flex-wrap: wrap;

    &.splide__list {
      flex-wrap: nowrap;
    }
  }

  &__list-item {
    padding-top: map-get($spacers, 3);
    padding-bottom: map-get($spacers, 8);
    flex-grow: 1; // [1]
    flex-shrink: 1; // [1]
    flex-basis: 0; // [1]
    min-width: 100vw;
    display: flex;

    @media (min-width: $screen-md-min) {
      padding-bottom: map-get($spacers, 9);
    }

    @media (min-width: $screen-md-min) {
      min-width: 50vw;
    }

    &:nth-child(odd) {
      @media (min-width: $screen-md-min) {
        padding-right: map-get($spacers, 5);
      }

      @media (min-width: $screen-lg-min) {
        padding-right: map-get($spacers, 7);
      }

      .m-promo-boxes.js-slider & {
        padding-right: 0;
      }
    }
  }

  &__item {
    position: relative;
    z-index: $zindex-ui;
    display: flex;
    flex-grow: 1;
    transition: box-shadow, $base-duration;

    &:hover,
    &:focus {
      @include fiesta-box-shadow;
      z-index: $zindex-ui + 10;
    }
  }

  &__media {
    position: relative; // required for object-fit fallback
    flex-grow: 1; // [1]
    flex-shrink: 1; // [1]
    flex-basis: 0; // [1]
    order: 2;
  }

  &__media-img {
    @include object-fit;
    position: absolute;
  }

  &__body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 2; // [1]
    flex-shrink: 1; // [1]
    flex-basis: 0; // [1]
    text-align: center;
    min-height: 375px;
    background-color: #fff;

    @media (min-width: $screen-lg-min) {
      flex-grow: 1;
    }
  }

  &__body-inner {
    padding: map-get($spacers, 8);
  }

  &__action {
    margin-top: map-get($spacers, 6);
  }

  &__indicators {
    position: relative;
    z-index: $zindex-ui + 20;
    margin-top: map-get($spacers, 6) / -1;

    @media (min-width: $screen-sm-min) {
      margin-bottom: map-get($spacers, 6) / -1;
    }
  }
}
