.m-advanced-layout {
  &__col-primary {
    z-index: $zindex-ui;
  }

  &__content {
    margin-bottom: map-get($spacers, 6);

    &--cols {
      @media (min-width: $screen-md-min) {
        column-count: 2;
        column-gap: $grid-gutter-width;
      }
    }
  }

  &__media-list {
    @include list-unstyled;
    position: relative;
    z-index: $zindex-ui;
    display: flex;
    margin-top: map-get($spacers, 3);
    margin-right: floor($grid-gutter-width / -4);
    margin-left: floor($grid-gutter-width / -4);

    @media (min-width: $screen-sm-min) {
      margin-right: ($grid-gutter-width / -2);
      margin-left: ($grid-gutter-width / -2);
      width: 115%;
    }

    @media (min-width: $screen-md-min) {
      margin-top: map-get($spacers, 8);
    }

    + .m-advanced-layout__media-list {
      margin-top: ($grid-gutter-width / 2);
      justify-content: center;

      @media (min-width: $screen-sm-min) {
        margin-top: $grid-gutter-width;
      }
    }
  }

  &__media-list-item {
    padding-right: ($grid-gutter-width / 4);
    padding-left: ($grid-gutter-width / 4);

    @media (min-width: $screen-sm-min) {
      padding-right: ($grid-gutter-width / 2);
      padding-left: ($grid-gutter-width / 2);
    }
  }

  &__item {
    @include fiesta-box-shadow;
    border: 3px solid #fff;
  }
}
