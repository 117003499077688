.m-footer {
  &__content {
    display: block;
    padding-top: map-get($spacers, 6);
    padding-bottom: map-get($spacers, 6);
    border-top: 1px solid $separator-color-light;
    border-bottom: 1px solid $separator-color-light;

    @media screen and (min-width: $screen-md-min) {
      padding-top: map-get($spacers, 7);
      padding-bottom: map-get($spacers, 7);
    }

    &--main {
      padding-bottom: 0;

      @media screen and (min-width: $screen-md-min) {
        padding-top: map-get($spacers, 9);
        padding-bottom: map-get($spacers, 9) - map-get($spacers, 6);
      }
    }

    &--tight {
      padding-top: map-get($spacers, 6);
      padding-bottom: map-get($spacers, 6);
    }

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__cols {
    margin-right: floor($grid-gutter-width / -2);
    margin-left: floor($grid-gutter-width / -2);
    display: flex;
    flex-wrap: wrap;
  }

  &__col {
    margin-bottom: map-get($spacers, 6);
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    flex: 1 1 300px;

    @media screen and (min-width: $screen-lg-min) {
      flex-basis: (100% / 4);

      &--logo {
        max-width: 25%;
        flex-basis: auto;
      }
    }

    .m-footer__cols--grid & {
      margin-bottom: map-get($spacers, 7);
    }
  }

  &__heading {
    margin-bottom: map-get($spacers, 6);

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__app-badges {
    margin-bottom: 0;
    margin-left: -10px;
    display: flex;
    justify-content: center;
  }

  &__logo-img {
    max-height: 150px;
  }
}
