.m-header {
  $m-header-navbar-bottom-spacing: 100px;
  position: relative;

  // `m-header__container` is similar to `container--xlg` but differs in laptops viewport - it provides more space for middle column in order to fit all navigation links in one line (according with the guidelines from Dropbox Paper).
  &__container {
    @include container-fixed;
    width: 100%;

    @media (min-width: $screen-md-min) {
      max-width: calc(100vw - #{ceil(50 + $grid-gutter-width) * 2}); // prevent overflow of the social icons and slider indicator

      &--navbar {
        max-width: none
      }
    }

    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
      padding-right: $grid-gutter-width;
      padding-left: $grid-gutter-width;

      // IE 10 and IE 11 hack
      @media screen and (-ms-high-contrast: none) {
        // Fix for weird IE 11 bug which causes even weirder flexbox bugs for some child elements in this viewport when width is 100% (e.g. wrong `m-header__main` height caused by wrong computed logo size in case of large logo).
        // This workaround has to be limited just for IE because in some other browsers it can cause horizontal scrollbar (e.g. Chrome 70 on Windows 7).
        width: 100vw;
      }
    }

    @media screen and (min-width: $screen-xlg-min) {
      width: $container-xlg;
    }

    &--horizontal-social-icons {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: $zindex-ui;

      @media (max-width: $screen-sm-max) {
        display: none;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @at-root .m-header--fixed-scroll.m-header--nav-bottom & {
      @media screen and (min-width: $screen-lg-min) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: $m-header-navbar-bottom-spacing;
      }
    }
  }

  &__main {
    position: relative;
    z-index: $zindex-ui + 100;

    @at-root .m-header--nav-bottom & {
      @media screen and (min-width: $screen-lg-min) {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000;
      }
    }
  }

  &__navbar {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;

    .m-header--home & {
      padding-top: 30px;
      padding-bottom: 30px;

      @media screen and (min-width: $grid-float-breakpoint) and (min-height: $screen-sm-min) {
        padding-top: 50px;
        padding-bottom: 50px;
      }
    }

    .m-header--nav-bottom & {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .m-header-navbar.is-affixed & {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__hero {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;

    &--has-steps {
      @media screen and (max-width: $screen-md-max) {
        position: relative;
      }
    }
  }

  &__logo {
    display: block;
    max-width: 40vw;

    &--gfb-hidden { // gfb = $grid-float-breakpoint
      @media (min-width: $grid-float-breakpoint) {
        display: none;
      }
    }
  }

  &__logo-img {
    max-height: 80px;

    @media screen and (min-width: $screen-sm-min) {
      max-height: 120px;
    }
  }

  &--full-coverage {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &--nav-bottom {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-bottom: $m-header-navbar-bottom-spacing;

    &.m-header--fixed-scroll {
      padding-bottom: 0;
    }
  }

  &--fixed-scroll {
    @extend .m-header--full-coverage;

    &:not(.m-header--nav-bottom) {
      @media screen and (min-width: $fixed-layout-breakpoint) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  // Required for legacy browsers in order to simulate object-fit behavior and support long dropdowns
  &__cover-wrapper {
    @include position(absolute, 0 0 0 0);
    overflow: hidden;
  }

  // Required for display bg image in PR
  &__bg-single-img {
    height: 100%;
  }

  &__cover,
  &__splitted-wrapper {
    opacity: 0.3;
  }

  &__cover {
    @include position(absolute, 0 0 0 0);
    min-width: 100%;
    min-height: 100%;
    max-width: none;

    @supports (object-fit: cover) {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    &--zoom {
      .m-header__bg-single-img &,
      .m-header__bg-slider.is-initialized & { // Splide
        animation: smoothZoom 30s cubic-bezier(.1, .5, .4, .3) both;
      }
    }
  }

  &__splitted-wrapper {
    height: 100%;
    background-position: 50% 50%;

    &.splitted-animation {
      position: absolute; // Prevent from taking layout space and pushing the actual slider content to the bottom.
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  &__social-icons {
    position: absolute;
    top: 50%;
    left: $grid-gutter-width / 2;
    transform: translateY(-50%);
    z-index: $zindex-ui;

    @media (max-width: $screen-sm-max) {
      display: none;
    }

    &--right {
      right: $grid-gutter-width / 2;
      left: auto;
    }

    &--right-bottom,
    &--left-bottom {
      top: auto;
      left: $grid-gutter-width / 2;
      bottom: floor($grid-gutter-width / 1.5);
      transform: none;
    }

    &--right-bottom {
      right: $grid-gutter-width / 2;
      left: auto;
    }
  }

  &__bg-slider {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    .splide__track,
    .splide__list,
    .splide__slide {
      width: 100%;
      height: 100%;
    }
  }

  &__about-us {
    position: relative;
    margin-top: map-get($spacers, 6);
    margin-bottom: map-get($spacers, 7);
  }

  &__steps {
    position: relative;
    margin-top: 0;
    margin-bottom: 0;

    &--right {
      @media screen and (min-width: $screen-md-min) {
        width: 50%;
        margin-left: auto;
      }
    }

    &--left {
      @media screen and (min-width: $screen-md-min) {
        width: 50%;
        margin-right: auto;
      }
    }
  }
}

.m-header-navbar {
  @include position(fixed, 0 0 null 0);
  transform: translateY(-100%);
  transition: transform $base-duration;
  visibility: hidden;
  background-color: #fff;
  color: #000;
  z-index: $zindex-navbar;

  &.is-affixed {
    @include fiesta-box-shadow;
    transform: translateY(0);
    visibility: visible;

    .m-header__logo-img {
      max-height: 60px;
    }
  }
}
