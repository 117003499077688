.m-info-box {
  display: flex;
  margin-bottom: -30px;
  margin-right: floor($grid-gutter-width / -2);
  margin-left: floor($grid-gutter-width / -2);

  @media screen and (max-width: $screen-sm-max) {
    flex-wrap: wrap;
  }

  &--column {
    display: block;
  }

  &__col {
    margin-bottom: 30px;
    padding-right: floor($grid-gutter-width / 2);
    padding-left: floor($grid-gutter-width / 2);
    flex: 1 1 320px;

    &--media,
    &--media-last {
      position: relative;
      display: flex;

      &:before {
        display: none;
        visibility: hidden;
        position: absolute;
        top: -15px;
        left: 50%;
        right: 0;
        height: 200px;
        mask-position: 100% 0;
        content: "";

        @media (min-width: $screen-lg-min) {
          top: -60px;
        }

        @media (min-width: $screen-lg-min) {
          right: 15px;
        }

        @supports (mask-image: url()) {
          visibility: visible;
        }
      }
    }

    &--media {
      @media screen and (min-width: $screen-xs-min) {
        padding-right: floor($grid-gutter-width / 2);
      }
    }

    &--media-last {
      @media screen and (min-width: $screen-xs-min) {
        padding-left: floor($grid-gutter-width / 2);
        order: 10;
      }
    }

    &--media-below {
      .m-magazine__item {
        &:nth-child(3) {
          @media (max-width: $screen-sm-min) {
            display: none;
          }
        }
      }
    }

    // Set the same padding on all sides in order to display element in different situations and with different backgrounds.
    &--equal-padding {
      padding: $grid-gutter-width;

      @media (min-width: $screen-sm-min) {
        padding: $grid-gutter-width;
      }

      @media (min-width: $screen-md-min) {
        padding: $base-spacing-large;
      }
    }

    // Variant with large background image on the side.
    &--cover-aside {
      margin-right: $grid-gutter-width;
      padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width ceil($grid-gutter-width / 2);

      @media (min-width: $screen-sm-min) {
        margin-right: 0;
        padding-top: $base-spacing-large;
        padding-right: $base-spacing-large;
        padding-bottom: $base-spacing-large;
        max-width: 85%;
      }

      @media (min-width: $screen-md-min) {
        max-width: 55%;
      }

      // Make sure `equal-padding` modifier has the highest priority.
      &.m-info-box__col--equal-padding {
        @extend .m-info-box__col--equal-padding;
      }
    }
  }

  &__image {
    position: relative;
    width: 40%; // simple fallback
    width: 40vw;

    @media screen and (min-width: $screen-sm-min) {
      left: map-get($spacers, 6);
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      width: 270px;
      left: map-get($spacers, 8);
    }

    @media screen and (min-width: $screen-lg-min) {
      width: auto;
    }

    &--featured {
      @include fiesta-box-shadow;
      position: relative;
      margin-left: -30%;
      margin-top: 30%;
      border: 3px solid #fff;
    }
  }

  &__action {
    margin: 0;
  }

  &__content {
    &--frame {
      position: relative;
      margin-bottom: 26px;
      padding-top: map-get($spacers, 6);
      padding-right: map-get($spacers, 6);
      padding-left: map-get($spacers, 6);
      text-align: center;
      border: 2px solid currentColor;

      .m-info-box__action {
        margin-bottom: -26px;
      }
    }

    .m-info-box--column & {
      margin-bottom: map-get($spacers, 6);

      @media screen and (min-width: $grid-float-breakpoint) {
        margin-bottom: map-get($spacers, 8);
      }
    }
  }
}
