.m-overlay {
  position: relative;
  display: block;

  &__item {
    display: block;
  }

  &__content {
    @include position(absolute, 0 0 0 0);
    margin: 0;
    opacity: 0;
    transition: opacity $base-duration;
    background-color: rgba(#000, $hover-opacity);

    &--bordered {
      &:before {
        border: 1px solid;
        position: absolute;
        top: 19px;
        right: 19px;
        bottom: 19px;
        left: 19px;
        content: "";
      }

      &:after {
        border: 1px solid;
        position: absolute;
        top: 26px;
        right: 26px;
        bottom: 26px;
        left: 26px;
        content: "";
      }
    }
  }

  &__icon {
    &,
    &:before {
      @include u-center;
      margin: 0;
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-size: 1.125rem;
      color: #fff;
      border-radius: 50%;
      border: 2px solid #fff;
    }

    &--simple {
      &,
      &:before {
        font-size: 4rem;
        border: none;
      }
    }
  }

  &.is-active,
  &:hover,
  &:focus {
    .m-overlay__content {
      opacity: 1;
    }
  }
}
