.m-contact {
  &__data {
    position: relative;

    @media (min-width: $grid-float-breakpoint) {
      display: flex;
      align-items: flex-start;
    }

    @media (min-width: $screen-xlg-min) {
      display: block;
      min-height: $m-map-height;
    }
  }

  &__item {
    @include fiesta-box-shadow;
    position: relative;
    margin-bottom: map-get($spacers, 5);
    padding: 20px; // 20px for extra border pattern
    z-index: $zindex-ui;

    @media (min-width: $grid-float-breakpoint) {
      min-width: 430px;
      max-width: 500px;
      display: inline-block;
    }

    @media (min-width: $screen-xlg-min) {
      margin-top: 140px;
      margin-left: 100px;
    }

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
    }
  }

  &__item-inner {
    position: relative;
    padding: map-get($spacers, 6);

    @media (min-width: $grid-float-breakpoint) {
      padding: map-get($spacers, 8);
    }
  }

  &__map {
    flex-grow: 4;

    @media (min-width: $grid-float-breakpoint) {
      margin-left: $grid-gutter-width / 2;
    }

    @media (min-width: $screen-xlg-min) {
      margin-left: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: $zindex-ui - 100;
    }
  }

  &__info {
    padding: map-get($spacers, 4);
  }
}
