$m-menu-teaser-featured-image-breakpoint: 1400px !default;
$m-menu-teaser-featured-image-breakpoint-max: $m-menu-teaser-featured-image-breakpoint - 1;

// 1. Shorthand notation doesn't work correctly in IE 11.
// 2. IE 11 fix.
// 3. Required for object fit fallback (see more in `@mixin object-fit-fallback` definition).
.m-menu-teaser {
  position: relative;

  &__wrapper {
    position: relative;
  }

  &__list-wrapper {
    &--extra-box {
      @include fiesta-box-shadow;
      padding: map-get($spacers, 7);
      background-color: #fff;
    }
  }

  &__list {
    @include list-unstyled;
    margin-top: map-get($spacers, 6) / -1;
    margin-bottom: map-get($spacers, 6) / -1;

    @media screen and (min-width: $grid-float-breakpoint) {
      column-count: 2;
      column-gap: map-get($spacers, 6);
    }

    &--featured-image {
      @media (min-width: $m-menu-teaser-featured-image-breakpoint) {
        display: flex;
        column-count: auto;
      }
    }
  }

  &__list-item {
    padding-top: map-get($spacers, 6);
    break-inside: avoid;
    border-top: 1px solid $separator-color;
    transform: translateZ(0); // fix the bug with box-shadow and column layout

    @media (min-width: $screen-xs-min) {
      display: flex;
      align-items: flex-start;
    }

    &:first-child {
      @media (min-width: $screen-sm-min) {
        border-top: 0;
      }

      .m-menu-teaser__list-col:first-child & {
        border-top: 0;
      }
    }
  }

  &__item-image {
    margin-right: map-get($spacers, 6);
    margin-bottom: map-get($spacers, 6);
    width: 100px;
    height: 100px;
    flex-shrink: 0; // [1]

    @media (min-width: $screen-sm-min) {
      width: auto;
      height: auto;
    }
  }

  &__item-body {
    flex-grow: 1; // [1]
  }

  &__list-col {
    flex-grow: 1; // [1]
    flex-basis: 33%; // [2]
    break-inside: avoid;

    @media (max-width: $m-menu-teaser-featured-image-breakpoint-max) {
      flex-shrink: 0; // [1]
      flex-basis: 0; // [1]
    }

     &--featured-image {
      position: relative; // [3]
      overflow: hidden; // [3]
      margin: $grid-gutter-width;
      min-width: 310px;
      flex-grow: 0;
      flex-basis: auto;

      @media (max-width: $m-menu-teaser-featured-image-breakpoint-max) {
        display: none;
      }
    }
  }

  &__featured-image {
    @media screen and (min-width: $m-menu-teaser-featured-image-breakpoint) {
      @include object-fit;
      position: absolute; // prevent list resizing in the case of small number of list items and a high image (TODO: it causes bug in edit mode due relative wrapper)
      color: #000;
    }
  }

  &__item-image {
    @include fiesta-box-shadow;
    border: 3px solid #fff;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__heading {
    margin: 0 0 10px;
    font-size: 1.25rem;
    line-height: 1.4;
    flex-grow: 1; // [1]

    &--price {
      margin-left: floor($grid-gutter-width / 2);
      text-align: right;
      white-space: nowrap;
    }
  }

  &__description {
    margin: 0 0 map-get($spacers, 6);
    opacity: $muted-opacity;
  }

  &__action {
    margin-top: map-get($spacers, 8);
    margin-bottom: 0;
    text-align: center;
  }
}
