.m-menu-hero {
  &__list {
    @include list-unstyled;
    margin-top: -25px;
    margin-bottom: 15px;
  }

  &__list-item {
    padding-top: 27px;
    border-bottom: 1px solid $separator-color-light;

    &:last-child {
      margin-bottom: 30px;
    }

    @media screen and (min-width: $screen-sm-min) {
      display: flex;
    }
  }

  &__item-action {
    margin-bottom: 27px;

    @media screen and (min-width: $screen-sm-min) {
      margin-left: floor($grid-gutter-width / 2);
    }
  }

  &__header {
    flex-grow: 1;
  }

  &__heading {
    margin-bottom: 10px;
  }

  &__description {
    margin: 0 0 27px;
    opacity: $muted-opacity;
  }

  &__action {
    margin-bottom: 0;

    .m-hero__col--center & {
      text-align: center;
    }
  }
}
