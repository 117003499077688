.m-slider {
  position: relative;

  &__indicators {
    position: absolute;
    bottom: 0;
    left: 50%;
    padding: 0 $grid-gutter-width floor($grid-gutter-width / 1.5);
    width: 100%;
    text-align: center;
    transform: translateX(-50%);
  }
}
