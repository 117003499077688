// 1. Very simple grid fallback.
// 1.1. Undo simple grid fallback for modern browsers.
.m-magazine {
  margin-right: auto;
  margin-left: auto;
  align-self: center;
  flex-grow: 1;

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $grid-gutter-width;

    @media (min-width: $screen-sm-min) {
      column-count: 3; // [1]
      column-gap: $grid-gutter-width; // [1]
      grid-template-columns: 1fr 1fr;
    }

    @supports (display: grid) {
      column-count: auto; // [1.1]

      .m-magazine__item {
        margin-bottom: 0; // [1.1]
      }
    }

    &--media-mosaic,
    &--media-grid {
      @media (min-width: $screen-sm-min) {
        grid-gap: $grid-gutter-width / 2;
      }

      @media (min-width: $screen-md-min) {
        margin-top: -70px;

        .m-info-box__col--media-below & {
          margin-top: 0;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    margin-bottom: $grid-gutter-width; // [1]
    break-inside: avoid; // [1]

    @media (min-width: $screen-sm-min) {
      grid-column: 2;
    }

    &:nth-child(1) {
      @media (min-width: $screen-sm-min) {
        grid-row: 1;
        grid-column: 1;

        .m-magazine__grid--media-grid & {
          grid-row: 1 / 3;
          margin-top: -70px;
          margin-bottom: 70px;
        }

        .m-info-box__col--media-below .m-magazine__grid--media-grid & {
          margin-top: 0;
        }
      }
    }

    &:nth-child(2) {
      @media (min-width: $screen-sm-min) {
        grid-row: 2;
        grid-column: 1;

        .m-magazine__grid--media-grid & {
          grid-row: auto;
          grid-column: 2;
        }
      }
    }

    &:nth-child(3) {
      @media (min-width: $screen-sm-min) {
        grid-row: 1 / 3;
        grid-column: 2;

        .m-magazine__grid--media-grid & {
          grid-row: auto;
        }

        .m-magazine__grid--media-mosaic & {
          grid-row: auto;
          align-self: flex-end;
        }
      }
    }
  }

  &__image-magazine {
    height: 100%;
    object-fit: cover;

    .m-magazine__grid--media-mosaic & {
      height: auto;
    }
  }
}
