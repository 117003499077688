html {
  font-size: $font-size-base;
}

body {
  font-size: 1rem;
}

img {
  @include img-responsive();
}

.stars {
  img {
    display: inline;
  }
}

.btn,
button,
a {
  transition: color, background-color, opacity, border-color, box-shadow;
  transition-duration: $base-duration;
}

p {
  margin: 0 0 $base-spacing;
}

.btn-link {
  font-size: 1.125rem;
  text-decoration: underline;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.configure {
    text-transform: none;
    font-size: inherit;
    font-family: inherit;
  }
}

.btn-creator,
.add-button {
  @extend .btn-sm;
}

.btn-icon {
  padding: 0;
  width: 50px;
  height: 50px;
}

.container {
  @media screen and (max-width: $screen-xs-max) {
    width: 100%; // Make sure container takes the whole space (e.g. in case it's flex item).
  }

  // @media screen and (min-width: $screen-sm-min) {
  //   padding-right: $grid-gutter-width;
  //   padding-left: $grid-gutter-width;
  // }

  &--xlg {
    @media screen and (min-width: $screen-xlg-min) {
      width: $container-xlg;
    }
  }

  &__limit,
  &--content {
    @media screen and (min-width: $screen-lg-min) {
      width: 1200px;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.btn-lg-xlg {
  @media screen and (min-width: $screen-lg-min) {
    @include button-size(
      $btn-padding-large-vertical,
      80px,
      $btn-font-size-large,
      $btn-line-height-large,
      $btn-border-radius-large
    );
  }
}

.dropdown-menu {
  animation: fadeIn $base-duration;
}

.text-success,
.icon-ok {
  color: $brand-success;
}

.content-content {
  > .form-inline {
    margin-bottom: $base-spacing;
  }
}

.m-content-box {
  margin-bottom: $base-spacing;

  @media screen and (min-width: $grid-float-breakpoint) {
    margin-bottom: 47px;
  }

  &--mb-gutter {
    margin-bottom: ceil($grid-gutter-height / 2);
  }
}

.item-counter,
.m-item__badge {
  @extend .badge;
}

.promotion-availability {
  &__label {
    margin-right: 0.625em;
    font-size: 1rem;
    text-transform: uppercase;
  }
}

// Group nav module
.m-group-nav {
  &__title {
    margin: 0;
  }

  &--horizontal {
    text-align: center;
    border-bottom: 1px solid $separator-color;

    .m-group-nav__link {
      &.is-active {
        &:after {
          @include line-ornament(100%, 3px, 0);
          position: relative;
          bottom: -12px;
        }
      }
    }

    .m-group-nav__title {
      @at-root .restaurant-menu & {
        display: none;
      }
    }
  }
}

// List module
.m-list {
  &__description {
    text-align: center;

    @media screen and (max-width: $grid-float-breakpoint-max) {
      border-top: 1px solid $separator-color;
    }

    @media screen and (min-width: $grid-float-breakpoint) {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @at-root .m-group--full-width & {
      @extend .container--content;
      margin-right: auto;
      margin-left: auto;
    }
  }
}

// Item module
.m-item {
  &__title {
    font-weight: $font-weight-bold;
  }

  &__price {
    font-size: 1.25rem;
  }
}

// Navigation
.m-nav-toggle {
  &__btn {
    .icon-reorder:before {
      transform: scale(1.25) translateY(-5%);
    }
  }
}

.mobile-navigation {
  display: flex;
  flex-direction: column;
  min-width: 60vw;

  @media (min-width: $screen-sm-min) {
    min-width: 410px;
  }

  &--right {
    .mobile-navigation__list {
      @media (min-width: $screen-md-min) {
        padding-left: 50px;

        .dropdown-menu {
          margin-left: -50px;
          padding-left: 50px;
        }
      }
    }
  }
}
