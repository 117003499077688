.m-icon-item {
  @extend .m-icon-group__item;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 3.75rem;
  height: 3.75rem;
  background-color: transparent;
  color: var(--color-primary);
  box-shadow: 0 0 0 5px color-mix(in hsl, var(--color-primary) 20%, transparent);
  text-align: center;
  border-radius: 50%;
  font-size: 1.5rem
}
