.m-section {
  &--inner-padding {
    padding-top: map-get($spacers, 6);
    padding-bottom: map-get($spacers, 6);

    @media screen and (min-width: $screen-sm-min) {
      padding-top: map-get($spacers, 9);
      padding-bottom: map-get($spacers, 9);
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-top: map-get($spacers, 10);
      padding-bottom: map-get($spacers, 10);
    }
  }

  &--top {
    padding-top: map-get($spacers, 6);

    @media screen and (min-width: $screen-sm-min) {
      padding-top: map-get($spacers, 9);
    }

    @media screen and (min-width: $screen-lg-min) {
      padding-top: map-get($spacers, 10);
    }
  }

  &--inner-padding-alt {
    @extend .m-section--inner-padding;

    @media screen and (min-width: $screen-lg-min) {
      padding-top: map-get($spacers, 11);
      padding-bottom: map-get($spacers, 11);
    }
  }
}
