.m-indicators {
  overflow: hidden; // prevent horizontal scroll in case of full width indicators and negative horizontal list margin

  &__list {
    margin-right: -5px;
    margin-bottom: 0;
    margin-left: -5px;
    padding-left: 0;
    list-style: none;
  }

  &__dots {
    .m-indicators--md-vertical & {
      @media screen and (min-width: $screen-md-min) {
        flex-direction: column;
      }
    }
  }

  li, // same as .m-indicators__list-item (required by Splide pagination)
  &__list-item {
    display: inline-block;
  }

  &__btn {
    @extend %button-unstyled;
    padding: 7px;

    &:before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      transition: background-color, width, height;
      transition-duration: $base-duration;
      text-indent: -999em;
      overflow: hidden;
      border: 2px solid;
    }
  }

  &__arrows {
    display: flex;
    justify-content: center;

    @media (min-width: $screen-md-min) {
      flex-direction: column;
    }

    > .btn {
      &:not(:first-child) {
        margin-left: floor($grid-gutter-height / 3);

        @media (min-width: $screen-md-min) {
          margin-top: floor($grid-gutter-height / 3);
          margin-left: 0;
        }
      }
    }
  }

  &--center {
    text-align: center;
  }

  &--light {
    .m-indicators__btn:before {
      border-color: #fff;
    }

    .m-indicators__btn {
      &[aria-expanded="true"], // support for Bootstrap tabs
      &.is-active {
        &:before {
          background-color: #fff;
        }

        &:hover,
        &:focus {
          &:before {
            background-color: darken(#fff, 10%);
          }
        }
      }

      &:hover,
      &:focus {
        &:before {
          border-color: darken(#fff, 10%);
        }
      }
    }
  }
}
