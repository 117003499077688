.m-info-boxes {
  &__list {
    @include list-unstyled;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;

    &--aside {
      display: block;

      @media (min-width: $screen-sm-min) {
        display: flex;
        flex-wrap: nowrap;
      }
    }
  }

  &__item {
    padding: map-get($spacers, 6);
    flex: 1 1 100%;
    width: 100%;
    border-bottom: 1px solid $separator-color;
    border-left: 1px solid $separator-color;

    @media (min-width: $screen-xs-min) {
      flex-basis: 50%;
    }

    @media (min-width: $screen-md-min) {
      padding: map-get($spacers, 8);
    }

    @media (min-width: $screen-lg-min) {
      padding: map-get($spacers, 9);
      flex-basis: 0;
    }

    .m-info-boxes--hero & {
      border: none;
      padding: map-get($spacers, 4);

      @media (min-width: $screen-xs-min) {
        flex-basis: 200px;
      }
    }

    .m-info-boxes--on-hero-bg & {
      padding-top: map-get($spacers, 7);
      padding-bottom: map-get($spacers, 7);
      border: none;
    }

    .m-info-boxes__list--aside & {
      padding: map-get($spacers, 5);

      @media (min-width: $screen-sm-min) {
        padding: map-get($spacers, 7) map-get($spacers, 5);
      }

      @media (min-width: $screen-lg-min) {
        padding: map-get($spacers, 7);
      }

      &:first-child {
        padding-top: map-get($spacers, 7);
      }

      &:last-child {
        padding-bottom: map-get($spacers, 7);
      }
    }
  }

  &__icon {
    margin-bottom: 1.5rem;
    line-height: 1;

    .m-info-boxes--hero & {
      margin-bottom: 1.25rem;
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 20px;

    .m-info-boxes--hero & {
      margin-bottom: 0.625rem;
      color: inherit;
    }
  }

  &__description {
    margin: 0;

    .m-info-boxes--hero & {
      font-size: 1.125rem;
      opacity: .8;
    }
  }

  &--hero {
    color: inherit;
    text-align: center;
  }
}
