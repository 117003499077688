.m-main {
  position: relative;
  z-index: $zindex-ui + 10;

  &--fixed-scroll {

    @media screen and (min-width: $fixed-layout-breakpoint) {
      margin-top: 100vh;
    }

    &.m-main--nav-bottom {
      margin-top: 0;
    }
  }

  &:before {
    position: absolute;
    display: none;
    top: 0;
    right: 0;
    left: 0;
    height: 30px;
    mask-repeat: repeat-x;
    mask-position: bottom;
    transform: translateY(-100%);
    content: "";

    @supports (mask-image: url()) {
      display: block;
    }
  }

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    content: "";
  }
}
