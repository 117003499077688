.m-grid-gallery {
  &__list {
    @include list-unstyled;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    padding-right: 10px;
    padding-left: 10px;

    @media (min-width: $screen-sm-min) {
      grid-template-columns: repeat(8, 1fr);
    }

    &--alternative {
      @media (min-width: $screen-sm-min) {
        grid-template-columns: repeat(6, 1fr);
      }
    }

    // IE 10 and IE 11 hack (basic fallback)
    @media screen and (-ms-high-contrast: none) {
      display: flex;
      padding: 5px;
      flex-wrap: wrap;
      align-items: center;

      .m-grid-gallery__list-item {
        padding: 10px;
        flex-basis: 25%;

        &:nth-child(5n+1) {
          flex-basis: 40%;
          flex-grow: 1;
        }
      }
    }

    &--slider {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  &__list-item {
    height: 100%;
    grid-column: span 2;

    &:nth-child(5n+1) {
      grid-column: span 4;
    }

    &:last-child {
      // Target last item if there are 2 items.
      &:nth-child(2) {
        @media (max-width: $screen-xs-max) {
          grid-column: 2 / 4;
        }
      }

      // Target last item if there are 4 items.
      &:nth-child(4) {
        @media (max-width: $screen-xs-max) {
          grid-column: 2 / 4;
        }
      }
    }

    // Target 3rd item if there are 4 items.
    &:nth-last-child(2):nth-child(3) {
      @media (min-width: $screen-sm-min) {
        grid-column: 2 / 4;
      }
    }

    // Target 4th item if there are 5 items.
    &:nth-last-child(2):nth-child(4) {
      @media (min-width: $screen-sm-min) {
        grid-column: 3 / 5;
      }
    }

    // Only one item.
    &:first-child:last-child {
      @media (min-width: $screen-sm-min) {
        grid-column: 2 / 6;
      }
    }
  }

  &__slider-list-item {
    height: 100%;
    grid-column: span 6;
    grid-row: span 2;

    @media (min-width: $screen-xs-min) {
      grid-column: span 4;
    }

    &:nth-child(1) {
      grid-column: 1 / 4;
      grid-row: 1;

      @media (min-width: $screen-xs-min) {
        grid-column: 1 / 3;
      }
    }

    &:nth-child(2) {
      grid-column: 4 / 7;
      grid-row: 1;

      @media (min-width: $screen-xs-min) {
        grid-column: 1 / 3;
        grid-row: 2;
      }
    }

    &--slider {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__item-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &__picture {
    height: 100%;
  }

  &__item {
    height: 100%;
  }

  &__action {
    margin-top: map-get($spacers, 8);
    margin-bottom: 0;
    text-align: center;
  }
}
