.m-nav {
  font-size: 1.125rem;

  &__list {
    margin: 0;
    padding-left: 0;
    list-style: none;
    text-align: center;
    font-weight: 400;

    @at-root .m-nav--logo-center & {
      @media screen and (min-width: $screen-sm-min) {
        flex: 1 0 (100% / 3);
      }
    }

    &--part1,
    &--part2 {
      @at-root .m-nav--logo-center & {
        @media screen and (max-width: $screen-sm-max) {
          display: none;
        }
      }
    }

    &--part1 {
      @at-root .m-nav--logo-center & {
        @media screen and (min-width: $screen-sm-min) {
          text-align: right;
        }
      }
    }

    &--part2 {
      @at-root .m-nav--logo-center & {
        @media screen and (min-width: $screen-sm-min) {
          text-align: left;
        }
      }
    }

    &--logo-center {
      @at-root .m-nav--logo-center & {
        @media screen and (max-width: $screen-sm-max) {
          text-align: left;
        }

        @media screen and (min-width: $screen-sm-min) {
          flex: 1 1 10%;
        }
      }
    }
  }

  &__list-item {
    display: inline-block;

    &--logo {
      $max-height: 70px;

      padding-right: floor($grid-gutter-width / 2);
      padding-left: floor($grid-gutter-width / 2);
      min-width: 120px;
      max-height: $max-height;

      img {
        max-height: $max-height;
      }
    }
  }

  &__item {
    display: block;
    padding: 3px 12px;

    &.is-active,
    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:focus {
      color: inherit;
    }

    @at-root .m-nav__list--logo-center & {
      &.dropdown-toggle {
        white-space: nowrap;
      }
    }
  }

  &__dropdown-list {
    font-weight: 400;
  }

  &__dropdown-item {
    &.is-active {
      background-color: $dropdown-link-hover-bg;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    display: none;
  }

  @media screen and (min-width: $screen-sm-min) {
    text-align: center;
  }

  &--logo-center {
    display: block;
    text-align: left;

    @media screen and (min-width: $screen-md-min) {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}
